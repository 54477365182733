import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Salvar pergunta")])]),_c(VDivider,{staticClass:"mx-3"}),_c(VCardText,{staticClass:"px-0 pb-0"},[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{attrs:{"elevation":"0"}},[_vm._t("default")],2)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")]),_c(VBtn,{attrs:{"loading":_vm.loadings.loadingModalPergunta,"color":"primary"},on:{"click":function($event){return _vm.$emit('clicked-on-save')}}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }