<template>
  <v-btn color="primary" x-small @click="setSuggestionQuestion()">
    <v-icon class="mr-1">mdi-alpha-e-box-outline</v-icon>
    Usar estrutura sugerida
  </v-btn>
</template>

<script>
export default {
  name: "SuggestedStructureButtonComponent",
  methods: {
    setSuggestionQuestion() {
      const question = {};

      switch (this.currentPositionInSteps.subcategory) {
        case "BEN":
          const questionBEN = {
            titulo: "Benefícios mais Relevantes",
            descricao:
              "Selecione em ordem de prioridade os benefícios ofertados pela empresa:",
            categoria: "FI",
            subcategoria: "BEN",
            tipo: "checkbox",
            limite_de_opcoes: null,
            fidelidade: 0,
            obrigatoriedade: true,
            posicao: "",
            opcoes: [
              {
                id: null,
                uuid: null,
                descricao: "Vale Transporte",
                posicao: 1,
              },
              {
                id: null,
                uuid: null,
                descricao: "Vale Alimentação",
                posicao: 2,
              },
              {
                id: null,
                uuid: null,
                descricao: "Vale Refeição",
                posicao: 3,
              },
            ],
          };
          Object.assign(question, questionBEN);
          break;
        case "DES":
          const questionDES = {
            titulo: "Fatores Desmotivacionais mais Relevantes",
            descricao:
              "Selecione em ordem de prioridade as três opções que mais te geram desmotivação:",
            categoria: "FI",
            subcategoria: "DES",
            tipo: "checkbox",
            limite_de_opcoes: 3,
            fidelidade: 0,
            obrigatoriedade: true,
            posicao: "",
            opcoes: [
              {
                descricao: "Impossibilidade de crescimento profissional",
                posicao: 1,
              },
              {
                descricao: "Ambiente de trabalho ruim",
                posicao: 2,
              },
              {
                descricao: "Ruídos na comunicação",
                posicao: 3,
              },
              {
                descricao: "Falta de autonomia",
                posicao: 4,
              },
              {
                descricao: "Falta de reconhecimento",
                posicao: 5,
              },
              {
                descricao: "Falta de treinamento",
                posicao: 6,
              },
              {
                descricao: "O trabalho que realizo",
                posicao: 7,
              },
              {
                descricao: "Relacionamento ruim com Gestor",
                posicao: 8,
              },
              {
                descricao: "Relacionamento ruim com Superior imediato",
                posicao: 9,
              },
              {
                descricao: "Salário",
                posicao: 10,
              },
              {
                descricao: "Falta de processos bem definidos",
                posicao: 11,
              },
              {
                descricao: "Outros",
                posicao: 12,
              },
            ],
          };
          Object.assign(question, questionDES);
          break;
        case "MOT":
          const questionMOT = {
            titulo: "Fatores Motivacionais mais Relevantes",
            descricao:
              "Selecione em ordem de prioridade as duas opções que mais te geram motivação:",
            categoria: "FI",
            subcategoria: "MOT",
            tipo: "checkbox",
            limite_de_opcoes: 2,
            fidelidade: 0,
            obrigatoriedade: 1,
            posicao: "",
            opcoes: [
              {
                descricao: "Chances de crescimento profissional",
                posicao: 1,
              },
              {
                descricao: "Dificuldade em mudar de empresa no atual cenário",
                posicao: 2,
              },
              {
                descricao: "Estabilidade no emprego",
                posicao: 3,
              },
              {
                descricao: "Falta de outro emprego",
                posicao: 4,
              },
              {
                descricao: "O ambiente de trabalho",
                posicao: 5,
              },
              {
                descricao: "O trabalho que realizo",
                posicao: 6,
              },
              {
                descricao: "Possibilidade de treinamento",
                posicao: 7,
              },
              {
                descricao: "Reconhecimento",
                posicao: 8,
              },
              {
                descricao: "Relacionamento com Superior imediato",
                posicao: 9,
              },
              {
                descricao: "Relacionamento com o Diretor",
                posicao: 10,
              },
              {
                descricao: "Salário",
                posicao: 11,
              },
              {
                descricao: "Outro",
                posicao: 12,
              },
            ],
          };
          Object.assign(question, questionMOT);
          break;
      }

      Object.assign(this.pergunta, question);
    },
  },
  computed: {
    pergunta: {
      get() {
        return this.$store.getters["pcoPerguntas/getPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoPerguntas/setPergunta", value);
      },
    },
    currentPositionInSteps: {
      get() {
        return this.$store.getters["pcoModelos/getCurrentPositionInSteps"];
      },
    },
  },
};
</script>

<style></style>
