<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <v-card elevation="0">
      <v-card-title>
        <span class="text-h5">Salvar pergunta</span>
      </v-card-title>
      <v-divider class="mx-3"></v-divider>
      <v-card-text class="px-0 pb-0">
        <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
        <v-container fluid>
          <v-card elevation="0">
            <slot></slot>
          </v-card>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
        <v-btn
          :loading="loadings.loadingModalPergunta"
          color="primary"
          @click="$emit('clicked-on-save')"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalPergunta",
  data: () => ({
    editedIndex: false,
  }),
  computed: {
    dialog: {
      get() {
        return this.$store.getters["pcoModelos/getDialogModalPergunta"];
      },
      set(value) {
        return this.$store.dispatch("pcoModelos/setDialogModalPergunta", value);
      },
    },
    loadings: {
      get() {
        return this.$store.getters["pcoModelos/getLoadings"];
      },
    },
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue === false) {
        this.$store.dispatch("pcoPerguntas/resetPergunta");
      }
    },
  },
};
</script>
